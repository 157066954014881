
html {
  height: 100%;
}

body {
  height: 100%;
}

div#root {
  height: 100%; /* remove this line to see div.app is no more 100% height */
  background-color: rgb(112, 102, 119);
  
}

div.app {
  height: 100%;
  background-color: cornsilk;
}