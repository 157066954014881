$bg-color-dark : #1d1818;
$bg-color-dark-grey : #353535;
$bg-color-first: rgb(22, 27, 98);
$bg-color-second: rgb(105, 53, 253);
// $bg-color-first: #212aa8;
// $bg-color-second: #a89519;
// $bg-color-first: #10144d;
// $bg-color-second: #4d1a1e;
$bg-color-third: #1e4d1b;
$bg-color-fourth: #4d2f31;
$bg-color-fifth: #314d2f;



.PageBg {
  // background-image: linear-gradient(135deg, $bg-color-first, $bg-color-second) !important;
  background-color: $bg-color-dark;
}


#sidebar-wrapper {
  transition:width 0.5s ease;
  background-color: $bg-color-dark-grey !important;
  // background-image: linear-gradient(135deg, $bg-color-third, $bg-color-second) !important;
}

#sidebar-wrapper-collapsed {
  transition:width 0.5s ease;
}


#sideBar{
  transition: 0.4s ease !important;
  background-color: transparent !important;
  // background-image: linear-gradient(135deg, $bg-color-first, $bg-color-second) !important;
}



.nav-link.active#sidebarlink {
  background-image: linear-gradient(135deg, rgba($bg-color-first, 40%) , rgba($bg-color-second, 40%)) !important;
}

.nav-link#sidebarlink {
  background-color: transparent !important;
}

.bgAssistant {
  background-image: linear-gradient(135deg ,rgba($bg-color-first, 50%) 10%, rgba($bg-color-third, 50%)) !important;
  background-color: transparent !important;
}

.bgUser {
  background-image: linear-gradient(135deg, rgba($bg-color-third, 50%) , rgba($bg-color-second, 50%)) !important;
  background-color: transparent !important;
}