$bg-color-dark : #1d1818;
$bg-color-dark-grey : #353535;
.CardHeader {
  background-color: $bg-color-dark-grey !important;
}

.CardBody {
  background-image: linear-gradient(to top, $bg-color-dark, $bg-color-dark-grey) !important;
}
.pageBg {
  background-image: linear-gradient(to right, $bg-color-dark, $bg-color-dark-grey) !important;
}

