$bg-color-dark : #1d1818;
$bg-color-dark-grey : #353535;
$bg-linear-bottom: linear-gradient(to bottom, $bg-color-dark 5%, $bg-color-dark-grey);
$bg-linear-top: linear-gradient(to top, $bg-color-dark 5%, $bg-color-dark-grey);
.HomePage {
  font-family: 'Vazirmatn', sans-serif;
}

.linearBgTop {
  background-image: linear-gradient(to top, $bg-color-dark, $bg-color-dark-grey) !important;
}
.linearBgBottom {
  background-image: linear-gradient(to bottom, $bg-color-dark, $bg-color-dark-grey) !important;
}

.FontVazir {
  font-family: 'Vazirmatn', sans-serif;
}

.HeroPage {
  // background-color: $bg-color-dark;
  background-image: $bg-linear-bottom !important;
}

.headline {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.styledSpan1 {
  background: linear-gradient(to right, rgba(33, 42, 168, 1), rgba(168, 149, 25, 1));
  -webkit-text-fill-color: transparent; 
  -webkit-background-clip: text; 
  background-clip:text
}

.styledText {
  background: linear-gradient(to right, rgba(33, 42, 168, 1), rgba(168, 149, 25, 1));
  -webkit-text-fill-color: transparent; 
  -webkit-background-clip: text; 
  background-clip:text
}

$size: 4rem;

.btn-custom-primary {
 
  color: #b4b4b4;
  background-image: linear-gradient(to right, rgba(22, 27, 98, 1) 10%, rgba(105, 53, 253, 1));
  border:rgba(22, 27, 98, 1);
  border-radius: 10px;
  width: 5*$size;
  height: 1*$size;
  transition: 0.4s;
}

.btn-custom-primary:hover, .btn-custom-primary:active, .btn-custom-primary:focus {
  color: #fff;
  box-shadow: 0px 0px 10px 0px whitesmoke;
  transition: 0.4s;
} 

.btn-custom-primary:active{
  filter: brightness(60%);
}

.check {
  height: $size * 0.5;
  width: $size * 0.5;
}

.sectionTwo {
  // background-color: $bg-color-dark-grey;
  background-image: $bg-linear-top !important;
}

.sectionThree {
  // background-color: $bg-color-dark;
  background-image: $bg-linear-bottom !important;
}

.FAQ {
  // background-color: $bg-color-dark-grey;
  background-image: $bg-linear-top !important;
}

.accordion-button{
  display: flex !important;
  justify-content: space-between !important;
}

.accordion-item, .accordion-button{
  background-color: $bg-color-dark !important;
  color: lightgrey !important;
  box-shadow:none !important;
  text-align: right !important;
}

.accordion-body {
  color: white !important;
}

.accordion-button::after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
  margin-right: auto;
  margin-left: 0 !important;
}

.CallToAction {
  background-color: $bg-color-dark;
}

.Disclaimer {
  // background-color: $bg-color-dark-grey;
  background-image: $bg-linear-bottom !important;
}
